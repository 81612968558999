import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/rezepte"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/durchfallmythen/" title>
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded active-trail">
                        <a
                          href="/richtig-essen/"
                          title
                          className="active-trail"
                        >
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf active-trail">
                            <a
                              href="/rezepte/"
                              title
                              className="active-trail active"
                            >
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>*/}
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">Leckere Rezepte</div>
                        </div>
                      </div>
                    </h1>
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div
                          className="field-item even"
                          property="content:encoded"
                        >
                          <h3>Beeren-Limetten-Shake</h3>
                          <p>
                            <strong>&nbsp;</strong>
                            <strong>
                              <img
                                alt
                                src="/sites/default/files/Blaubeershake.JPG"
                                style={{
                                  width: "220px",
                                  height: "330px",
                                  float: "right",
                                  margin: "12px 100px"
                                }}
                              />
                              Zutaten für 4 Portionen:
                            </strong>
                          </p>
                          <ul
                            style={{
                              "margin-top": "0pt"
                            }}
                          >
                            <li>100 g Heidelbeeren</li>
                            <li>50 g schwarze Johannisbeeren</li>
                            <li>400 ml Sojamilch</li>
                            <li>1 Prise Zimt</li>
                            <li>1 St unbehandelte Limette (Schale, Saft)</li>
                            <li>2 EL Honig</li>
                            <li>etwas frische Zitronenmelisse</li>
                          </ul>
                          <p>
                            <strong>Zeitaufwand:</strong>
                            <br />
                            Vorbereitungszeit - 15 min
                            <br />
                            Koch-/Kühlzeit - 0 min
                            <br />
                            Zubereitungszeit - 15 min
                          </p>
                          <p>
                            <strong>Zubereitung</strong>:<br />
                            Beeren unter klarem Wasser abspülen, verlesen und
                            auf einem Tuch abtropfen lassen. Kalte Sojamilch,
                            Limettensaft, Honig und Zimt mit den Früchten
                            mischen. Mit dem Mixer oder Pürierstab pürieren und
                            Shake in 2 Gläser umfüllen. Melisse abspülen und
                            trocken schütteln. Anschliessend&nbsp;Blätter von
                            den Stielen zupfen und den Shake mit Blättern und
                            Limettenschale dekorieren.
                          </p>
                          <p>
                            <a
                              className="georgia arrow_01"
                              href="/sites/default/files/Imodium_rezept_blaubeershake.pdf"
                              target="_blank"
                            >
                              Download Rezept
                            </a>
                          </p>
                          <p>&nbsp;</p>
                          <h3>Spargel-Carpaccio mit Putenbrust</h3>
                          <p>
                            <strong>Zutaten für 4 Portionen</strong>:
                          </p>
                          <ul>
                            <li>300 g Putenbrust Aufschnitt</li>
                            <li>150 g Grüner Spargel</li>
                            <li>150 g Weisser Spargel</li>
                            <li>50 g Cocktailtomaten</li>
                            <li>30 g Rucolasalat, gezupft</li>
                            <li>20 g Süsser Senf</li>
                            <li>1 TL rote Pfefferkörner</li>
                            <li>50 ml Olivenöl</li>
                            <li>1 Zweig Kerbel</li>
                            <li>Salz &amp; Pfeffer aus der Mühle</li>
                            <li>Saft von einer kleinen Zitrone</li>
                          </ul>
                          <p>
                            <strong>Zeitaufwand:</strong>
                            <br />
                            Vorbereitungszeit - 40 min
                            <br />
                            Koch-/Kühlzeit - 0 min
                            <br />
                            Zubereitungszeit - 40 min
                          </p>
                          <p>
                            <strong>Zubereitung</strong>:<br />
                            Spargel schälen und holzige Enden abschneiden.
                            Spargelstangen in gesalzenem Wasser bissfest kochen.
                            Im Anschluss kalt abspülen und für 30 Minuten in das
                            Tiefkühlfach legen. Leicht gefrorene Spargeln in
                            dünnen Streifen (wie bei einem Carpaccio) schneiden
                            und mit Salz und Pfeffer würzen. Auch den
                            Putenbrust-Aufschnitt in dünne Streifen schneiden
                            und zu dem Spargel geben. Zuletzt die Tomaten
                            waschen und vierteln und ebenfalls dazu geben. Mit
                            dem Zitronensaft und Olivenöl marinieren und einigen
                            Pfefferkörnern und Kerbel dekorieren.
                          </p>
                          <p>
                            Nährwertangaben: Pro Portion: 18 g E, 14 g F, 3 g
                            KH, 218 kcal
                          </p>
                          <p>&nbsp;</p>
                          <h3>Saiblingfilet mit Gemüsegarnitur</h3>
                          <p>
                            <strong>Zutaten für 4 Portionen</strong>:
                          </p>
                          <ul>
                            <li>800 g Saiblingfilet</li>
                            <li>2 Tomaten</li>
                            <li>1 kleine Zucchini</li>
                            <li>200 g Champignons</li>
                            <li>1/2 kleiner Staudensellerie</li>
                            <li>Meersalz</li>
                            <li>Pfeffer aus der Mühle</li>
                            <li>1 EL Walnussöl</li>
                            <li>Petersilie</li>
                          </ul>
                          <p>
                            <strong>Zeitaufwand:</strong>
                            <br />
                            Vorbereitungszeit - 30 min
                            <br />
                            Koch-/Kühlzeit - 0 min
                            <br />
                            Zubereitungszeit - 30 min
                            <br />
                            &nbsp;
                          </p>
                          <p>
                            <strong>Zubereitung</strong>:<br />
                            Beginnen Sie damit das Gemüse zu waschen. Schneiden
                            Sie Zucchini und Champignons in ca. 0,5cm dicke
                            Scheiben, den Staudensellerie in 1-2cm lange Stücke
                            und achteln Sie die Tomaten. Anschliessend&nbsp;die
                            Petersilie waschen und grob hacken. Mischen Sie
                            Gemüse und Petersilie in einer Schüssel und würzen
                            Sie es mit grobem Pfeffer aus der Mühle und
                            Meersalz. Bereiten Sie 4 Stücke Alufolie von 30x50cm
                            vor und bestreichen Sie diese mit Wallnussöl. Legen
                            Sie ein Saiblingfilet auf jedes Stück und würzen Sie
                            den Fisch. Geben Sie auf die Filets je ein Viertel
                            der Gemüsestücke und wickeln Sie alles fest in die
                            Alufolie ein. Die Päckchen nun für ca 15-20 Minuten
                            bei moderater Hitze auf den Grill geben.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
